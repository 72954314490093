import * as React from "react"
import ReactPlayer from 'react-player'
import videoBackground from '../videos/animation-breaking-good.mp4'
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section class="page-section">
      <ReactPlayer
        url={videoBackground}
        playsinline
        playing
        loop
        muted
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
        }}
      />
    </section>
    <section class="page-section contact">
      Email: contact@breakingoodworldwide.com
    </section>
  </Layout>
)

export default IndexPage
